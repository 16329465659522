var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.reclamation.organization.id,"filterable":true,"showAll":false,"organizationsIds":_vm.organizationsIds,"disabled":!!_vm.reclamation.id},on:{"organizationChanged":(organizationId, organization) => {
              _vm.reclamation.organization.id = organizationId;
              _vm.onFormChanged();
            }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')} (*)`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('location-selector',{attrs:{"location":_vm.reclamation.location.id,"filterable":true,"showAll":false,"transaction":_vm.transaction,"disabled":!_vm.reclamation.organization.id,"organization":_vm.reclamation.organization.id},on:{"locationChanged":(locationId, location) => {
            _vm.reclamation.location.id = locationId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.TRANSACTION')} (*)`,"placeholder":_vm.$t('COMMON.TRANSACTION')}},[_c('transaction-selector',{attrs:{"transaction":_vm.reclamation.transaction.id,"filterable":true,"showAll":false,"disabled":!_vm.reclamation.location.id && !_vm.transaction,"location":_vm.reclamation.location.id},on:{"transactionChanged":(transactionId, transaction) => {
            _vm.reclamation.transaction.id = transactionId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.transaction}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('RECLAMATIONS.MOTIF')} (*)`,"placeholder":_vm.$t('RECLAMATIONS.MOTIF')}},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"clearable":true,"placeholder":_vm.$t('RECLAMATIONS.MOTIF')},on:{"change":_vm.onFormChanged},model:{value:(_vm.reclamation.motif),callback:function ($$v) {_vm.$set(_vm.reclamation, "motif", $$v)},expression:"reclamation.motif"}},_vm._l((_vm.RECLAMATIONS_MOTIFS),function(item,key){return _c('el-option',{key:key,staticClass:"select-primary",attrs:{"label":_vm.$t(`RECLAMATIONS.MOTIF_${item}`),"value":item}})}),1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.motif}})],1),(_vm.reclamation.motif == 'OTHER')?_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":`${_vm.$t('RECLAMATIONS.OTHER_MOTIF')}`,"placeholder":_vm.$t('RECLAMATIONS.OTHER_MOTIF')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.reclamation.other_motif),callback:function ($$v) {_vm.$set(_vm.reclamation, "other_motif", $$v)},expression:"reclamation.other_motif"}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper full"},[_c('attachements-selector',{attrs:{"label":_vm.$t('RECLAMATIONS.ATTACHEMENTS'),"defaultAttachements":_vm.reclamation.attachements,"ressource_name":"reclamations","ressource_id":_vm.reclamation.id ? _vm.reclamation.id : 0,"field":"attachements"},on:{"attachementsChanged":(attachements_urls) => {
          _vm.reclamation.attachements = attachements_urls;
          _vm.onFormChanged();
        }}})],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":_vm.$t('RECLAMATIONS.EXCERPT'),"placeholder":_vm.$t('RECLAMATIONS.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.reclamation.excerpt),callback:function ($$v) {_vm.$set(_vm.reclamation, "excerpt", $$v)},expression:"reclamation.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.reclamation.id ? _vm.$t("RECLAMATIONS.EDIT_RECLAMATION") : _vm.$t("RECLAMATIONS.ADD_RECLAMATION"))+" ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }