<template>
  <div class="container-fluid">
    <reclamation-form
      :loading="loading"
      :transaction="transaction"
      :reclamationData="reclamation"
      :formErrors="formErrors"
      @reclamationSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultReclamation from "../defaultReclamation";
import ReclamationForm from "../partials/ReclamationForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ReclamationForm,
  },

  mixins: [alertLeave],
  props: ["transaction"],

  data() {
    const reclamation = cloneDeep(defaultReclamation);
    const me = this.$store.getters["profile/me"];
    return {
      reclamation: reclamation,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(reclamation) {
      this.loading = true;

      const reclamationData = cloneDeep(reclamation);
      delete reclamationData.id;

      try {
        await this.$store.dispatch("reclamations/add", reclamationData);
        this.$notify({
          type: "success",
          message: this.$t("RECLAMATIONS.RECLAMATION_ADDED"),
        });
        const reclamation = this.$store.getters["reclamations/reclamation"];
        if(this.transaction){
          this.$emit("reclamationCreated");
        }else{
          this.$emit("onViewReclamation", reclamation, true);
        }
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
