<template>
  <div class="elite-tabs-wrapper-content">
    <div class="title-header">
      <div class="row">
        <div class="col-6">
          <collecte :collecte="collecte"/>
        </div>
        <div class="col-6 text-right">
          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="collecte.status == 'DRAFT'"
            @click="validateCollecte(collecte)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-check"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COLLECTES.VALIDATE_COLLECTE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="collecte.status == 'PENDING'"
            @click="collectCollecte(collecte)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-angle-double-down"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COLLECTES.COLLECT_COLLECTE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="collecte.status != 'DELIVERED'"
            @click="deliverCollecte(collecte)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-angle-double-up"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COLLECTES.DELIVER_COLLECTE") }}
            </span>
          </base-button>

          <base-button
            class="elite-button add"
            icon
            size="sm"
            v-if="collecte.status == 'DRAFT' || collecte.status == 'PENDING' || collecte.status == 'COLLECTED'"
            @click="cancelCollecte(collecte)"
          >
            <span class="btn-inner--icon">
              <i class="far fa-times"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("COLLECTES.CANCEL_COLLECTE") }}
            </span>
          </base-button>
          
          
        </div>
      </div>
    </div>

    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COLLECTES.STATUS") }}</dt>
          <dd>
            <badge :type="getDeliveryBadgeType()">
              {{ $t(`COLLECTES.STATUS_${collecte.status}`) }}
            </badge>
          </dd>
        </dl>

        <dl class="row" v-if="collecte.collected_at">
          <dt>{{ $t("COLLECTES.COLLECTED_AT") }}</dt>
          <dd>
            {{
              $formatDate(collecte.collected_at, "dddd D MMMM YYYY")
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COLLECTES.COLLECTED_LOCATION") }}</dt>
          <dd>
            <div v-html="collecte.collect_location"></div>
          </dd>
        </dl>

        <dl class="row" v-if="collecte.delivered_at">
          <dt>{{ $t("COLLECTES.DELIVERED_AT") }}</dt>
          <dd>
            {{
              $formatDate(collecte.delivered_at, "dddd D MMMM YYYY")
            }}
          </dd>
        </dl>
        
        <dl class="row">
          <dt>{{ $t("COLLECTES.DELIVERED_LOCATION") }}</dt>
          <dd>
            <div v-html="collecte.delivery_location"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="collecte.created_at">
            {{ $formatDate(collecte.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="collecte.updated_at">
            {{ $formatDate(collecte.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import Collecte from "@/components/Collecte";
import CollecteStatusBadgeComponent from "../components/CollecteStatusBadgeComponent";
import {
  STATUS_DELIVERED,
  STATUS_CANCELED,
  STATUS_PENDING,
  STATUS_DRAFT,
  STATUS_COLLECTED,
} from "@/constants/transactions";
export default {
  name: "collecte-view-global",

  components: {
    Collecte,
    CollecteStatusBadgeComponent
  },

  props: ["collecte"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    validateCollecte() {
      this.$emit("onValidateCollecte", this.collecte);
    },
    collectCollecte() {
      this.$emit("onCollectCollecte", this.collecte);
    },
    deliverCollecte() {
      this.$emit("onDeliverCollecte", this.collecte);
    },
    cancelCollecte() {
      this.$emit("onCancelCollecte", this.collecte);
    },
    editCollecte() {
      this.$emit("onEditCollecte", this.collecte);
    },
    deleteCollecte() {
      this.$emit("onDeleteCollecte", this.collecte);
    },
    getDeliveryBadgeType() {
      switch (this.collecte.status) {
        case STATUS_COLLECTED:
          return "success";
        case STATUS_PENDING:
          return "yellow";
        case STATUS_DRAFT:
          return "info";
        case STATUS_CANCELED:
          return "danger";
        case STATUS_DELIVERED:
          return "primary";
        default:
          break;
      }
      return "default";
    },
  },

  mounted() {},

  watch: {
    collecte(collecte) {},
  },
};
</script>
<style lang="scss" scoped>
  .badge {
    text-transform: capitalize;
    font-size: 14px !important;
  }
</style>
