<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)">
        <!-- Organization -->
        <base-input
          :label="`${$t('COMMON.ORGANIZATION')} (*)`"
          :placeholder="$t('COMMON.ORGANIZATION')"
          
        >
          <organization-selector
            :organization="reclamation.organization.id"
            :filterable="true"
            :showAll="false"
            :organizationsIds="organizationsIds"
            @organizationChanged="
              (organizationId, organization) => {
                reclamation.organization.id = organizationId;
                onFormChanged();
              }
            "
            :disabled="!!reclamation.id"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.organization" />
      </div>
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
    >
      <!-- Location -->
      <base-input
        :label="`${$t('COMMON.LOCATION')} (*)`"
        :placeholder="$t('COMMON.LOCATION')"
      >
        <location-selector
          :location="reclamation.location.id"
          :filterable="true"
          :showAll="false"
          :transaction="transaction"
          :disabled="!reclamation.organization.id"
          :organization="reclamation.organization.id"
          @locationChanged="
            (locationId, location) => {
              reclamation.location.id = locationId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.location" />
    </div>
    <div
      class="form-wrapper"
    >
      <!-- Transaction -->
      <base-input
        :label="`${$t('COMMON.TRANSACTION')} (*)`"
        :placeholder="$t('COMMON.TRANSACTION')"
      >
        <transaction-selector
          :transaction="reclamation.transaction.id"
          :filterable="true"
          :showAll="false"
          :disabled="!reclamation.location.id && !transaction"
          :location="reclamation.location.id"
          @transactionChanged="
            (transactionId, transaction) => {
              reclamation.transaction.id = transactionId;
              onFormChanged();
            }
          "
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.transaction" />
    </div>
    <div class="form-wrapper">
        <base-input
          :label="`${$t('RECLAMATIONS.MOTIF')} (*)`"
          :placeholder="$t('RECLAMATIONS.MOTIF')"
        >
          <el-select
            :clearable="true"
            class="select-primary pagination-select"
            :placeholder="$t('RECLAMATIONS.MOTIF')"
            v-model="reclamation.motif"
            @change="onFormChanged"
          >
            <el-option
              class="select-primary"
              v-for="(item, key) in RECLAMATIONS_MOTIFS"
              :key="key"
              :label="$t(`RECLAMATIONS.MOTIF_${item}`)"
              :value="item"
            >
            </el-option>
          </el-select>
        </base-input>
        <validation-error :errors="apiValidationErrors.motif" />
      </div>
    <div class="form-wrapper full" v-if="reclamation.motif == 'OTHER'">
      <base-input
        :label="`${$t('RECLAMATIONS.OTHER_MOTIF')}`"
        :placeholder="$t('RECLAMATIONS.OTHER_MOTIF')"
        v-model="reclamation.other_motif"
        @change="onFormChanged()"
      />
    </div>
    <div class="form-wrapper full">
      <attachements-selector
        :label="$t('RECLAMATIONS.ATTACHEMENTS')"
        :defaultAttachements="reclamation.attachements"
        ressource_name="reclamations"
        :ressource_id="reclamation.id ? reclamation.id : 0"
        field="attachements"
        @attachementsChanged="
          (attachements_urls) => {
            reclamation.attachements = attachements_urls;
            onFormChanged();
          }
        "
      />
    </div>

    <div class="form-wrapper full">
      <base-input
        :label="$t('RECLAMATIONS.EXCERPT')"
        :placeholder="$t('RECLAMATIONS.EXCERPT')"
      >
        <html-editor v-model="reclamation.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          reclamation.id
            ? $t("RECLAMATIONS.EDIT_RECLAMATION")
            : $t("RECLAMATIONS.ADD_RECLAMATION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import Vue from "vue";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import models from "@/constants/models";
import "flatpickr/dist/flatpickr.css";
import { RECLAMATION_STATUS, RECLAMATIONS_MOTIFS } from "@/constants/reclamations";
import TransactionSelector from "@/components/TransactionSelector.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import AttachementsSelector from "@/components/AttachementsSelector.vue";

export default {
  layout: "DashboardLayout",

  components: {
    BaseInput,
    HtmlEditor,
    ValidationError,
    OrganizationSelector,
    LocationSelector,
    TransactionSelector,
    AttachementsSelector,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["reclamationData", "formErrors", "loading","transaction"],

  data() {
    let reclamationData = cloneDeep(this.reclamationData);
    reclamationData = this.$fillUserOrganizationData(reclamationData);
    return {
      reclamation: reclamationData,
      RECLAMATION_STATUS,
      RECLAMATIONS_MOTIFS,
      permissions: [],
      permissionsModel: {},
      models: models,
      organizationsIds : [],
    };
  },

  created() {
    if (this.transaction) {
      this.organizationsIds.push(this.transaction.seller.id);
      this.organizationsIds.push(this.transaction.buyer.id);
      this.reclamation.transaction.id = this.transaction.id;
    }
  },

  mounted() {},

  methods: {
    async handleSubmit() {
      let reclamationData = cloneDeep(this.reclamation);
      reclamationData = this.$fillUserOrganizationData(reclamationData);
      this.$emit("reclamationSubmitted", reclamationData);
    },
    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    reclamationData(reclamationData) {
      if (reclamationData) {
        this.reclamation = {
          ...this.reclamation,
          ...cloneDeep(reclamationData),
        };
      }
    },
  },
};
</script>
