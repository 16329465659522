export default {
  type: "reclamations",
  status: null,
  motif: null,
  other_motif: null,
  excerpt: "",
  attachements: null,
  motif_end: null,
  description_end: null,
  relationshipNames: ["organization", "location", "transaction"],
  organization: {
    type: "organizations",
    id: null,
  },
  location: {
    type: "locations",
    id: null,
  },
  transaction: {
    type: "transactions",
    id: null,
  },
};
