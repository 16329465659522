<template>
  <div class="container-fluid">
    <transaction-form
      :loading="loading"
      :vehicleId="vehicleId"
      :buyerId="buyerId"
      :price="price"
      :transactionData="transaction"
      :formErrors="formErrors"
      @transactionSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import defaultTaxes from "@/constants/defaultTaxes";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultTransaction from "../defaultTransaction";
import TransactionForm from "../partials/TransactionForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    TransactionForm,
  },

  mixins: [alertLeave],
  props: ["vehicleId", "buyerId", "price"],

  data() {
    const transaction = cloneDeep(defaultTransaction);
    const me = this.$store.getters["profile/me"];
    return {
      transaction: transaction,
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(transaction) {
      this.loading = true;

      const transactionData = cloneDeep(transaction);
      delete transactionData.id;

      try {
        await this.$store.dispatch("transactions/add", transactionData);
        this.$notify({
          type: "success",
          message: this.$t("TRANSACTIONS.TRANSACTION_ADDED"),
        });
        const transaction = this.$store.getters["transactions/transaction"];
        this.$emit("onViewTransaction", transaction, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
